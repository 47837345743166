import { createRootRouteWithContext, Outlet, ScrollRestoration, Link } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useTranslation } from 'react-i18next'
import type { QueryClient } from '@tanstack/react-query'
import { useBeacon } from '@/hooks/useBeacon'
import { NavMenu } from '@shopify/app-bridge-react'
import { Footer } from '@/components/shared/Footer'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: Component,
})

function Component() {
  const { t } = useTranslation()

  const { hasBeacon, registerBeacon, populateBeacon } = useBeacon()

  if (!hasBeacon) {
    registerBeacon()
  }

  populateBeacon()

  return (
    <>
      <NavMenu>
        <Link to="/" rel="home">
          {t('navDashboard')}
        </Link>
        <Link to="/preorders">{t('navPreorders')}</Link>
        <Link to="/backorders">{t('navBackorders')}</Link>
        <Link to="/back-in-stock">{t('navBackInStock')}</Link>
        <Link to="/display">{t('navDisplay')}</Link>
        <Link to="/settings">{t('navSettings')}</Link>
        <Link to="/plans">{t('navAppPlans')}</Link>
        <Link to="/emails">{t('navEmailTemplates')}</Link>
        <Link to="/localization">{t('navLocalization')}</Link>
      </NavMenu>
      <ScrollRestoration />
      <Outlet />
      <Footer />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  )
}
